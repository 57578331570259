(() => {
    function initMusicPlayer()
    {
        const musicPlayer = new MusicPlayer();
        document.addEventListener('click', async function (e) {
            const musicLoader = e.target.closest('.music-loader');
            if (musicLoader != null) {
                switch (musicLoader.dataset.type) {
                    case 'playlist':
                    {
                        try
                        {
                            const result = await fetch('/api/playlists/' + musicLoader.dataset.id);
                            if (result.ok)
                            {
                                const payload = (await result.json()).payload;
                                const songs = [];

                                for (let song of payload.songs)
                                {
                                    songs.push({
                                        url: song.song_url,
                                        playlistTitle: payload.name,
                                        title: song.name
                                    });
                                }

                                await musicPlayer.loadSongs(songs);
                            }
                        }
                        catch (e){}

                        return;
                    }
                    case 'song':
                    {
                        try
                        {
                            const result = await fetch('/api/songs/' + musicLoader.dataset.id);
                            if (result.ok)
                            {
                                const payload = (await result.json()).payload;
                                await musicPlayer.loadSong({
                                    url: payload.song_url,
                                    playlistTitle: '',
                                    title: payload.name
                                });
                            }
                        }
                        catch (e){}

                        return;
                    }
                    case 'link':
                    {
                        try
                        {
                            const result = await fetch('/api/links/' + musicLoader.dataset.id);
                            if (result.ok)
                            {
                                const payload = (await result.json()).payload;
                                window.open(payload.link, '_blank');
                            }
                        }
                        catch (e){}

                        return;
                    }
                    default:
                        return;
                }
            }
        });
    }

    const sliders = [];

    function defineBasicSliders() {
        const projectsSliderDivs = document.getElementsByClassName('project-slider');
        const pictureSliderDivs = document.getElementsByClassName('picture-slider');

        for (let projectsSliderDiv of projectsSliderDivs) {
            sliders.push(new Slide(projectsSliderDiv, {
                activeClass: 'open',
                swipeDuration: 0.5,
                dots: projectsSliderDiv.parentElement.getElementsByClassName('dots')[0]
            }));
        }

        for (let pictureSliderDiv of pictureSliderDivs) {
            sliders.push(new Slide(pictureSliderDiv, {
                activeClass: 'open',
                swipeDuration: 0.5,
                dots: pictureSliderDiv.parentElement.getElementsByClassName('dots')[0]
            }));
        }
    }

    switch (document.body.id)
    {
        case 'index-page':
        {
            const mainSlideDiv = document.getElementById('main-slide-cards');
            sliders.push(new Slide(mainSlideDiv,{
                activeClass: 'open',
                swipeDuration: 0.5,
                leftOffset: 0.5,
                dots: mainSlideDiv.parentElement.getElementsByClassName('dots')[0]
            }));

            defineBasicSliders();

            initMusicPlayer();
            break;
        }
        case 'projects-page':
        {
            defineBasicSliders();

            initMusicPlayer();
            break;
        }
    }

    for (let slider of sliders)
    {
        const element = slider.getElement();
        const projectsSwipe = new SwipeEvent({
            element: element,
            threshold: 10
        });

        projectsSwipe.attach();
        element.addEventListener('swipe', function (e) {
            if (e.detail.direction === 'right')
                slider.selectPrevPage();
            else if (e.detail.direction === 'left')
                slider.selectNextPage()
        });
    }

    const toggleNav = document.querySelectorAll('.toggle-nav');
    const showNav = document.querySelector('.toggle-nav.show-nav');
    const mobileNav = document.getElementById('mobile-nav');
    for (let navButton of toggleNav)
    {
        navButton.addEventListener('click', function () {
            mobileNav.classList.toggle('active');
            showNav.classList.toggle('active', !mobileNav.classList.contains('active'))
        });
    }
})();
